<template>
  <v-footer
    :class="$vuetify.theme.dark ? 'darkClass' : 'grey lighten-3'"
    bottom
    class="subtitle-1"
  >
    <v-row class="" justify="center">
      <v-col class="pb-0 px-0" cols="12">
        <v-card height="80" class="text-center py-4" width="100%" flat>
          <v-card-title
            class="py-0"
            :class="$vuetify.breakpoint.smAndDown ? 'text-center' : ''"
          >
            <v-col cols="12" v-if="$vuetify.breakpoint.smAndDown">
              <img src="/media/logos/footer_logo.svg" />
            </v-col>
            <component
              :is="$vuetify.breakpoint.smAndDown ? 'v-col' : 'div'"
              cols="12"
              class="bodyFont"
            >
              <div>
                <a
                  href="https://testapp.io/"
                  class="text-muted text-hover-primary"
                  target="_blank"
                >
                  Home
                </a>
                <a
                  href="https://help.testapp.io/"
                  class="text-muted mx-2 text-hover-primary"
                  target="_blank"
                >
                  Help
                </a>
                <a
                  href="https://blog.testapp.io/"
                  class="text-muted text-hover-primary mx-2"
                  target="_blank"
                >
                  Blog
                </a>
                <a
                  href="https://help.testapp.io/topic/integrations/"
                  class="text-muted text-hover-primary mx-2"
                  target="_blank"
                >
                  Integrations
                </a>
              </div>
            </component>
            <v-spacer></v-spacer>
            <div v-if="$vuetify.breakpoint.mdAndUp">
              <img src="/media/logos/footer_logo.svg" />
            </div>
            <v-spacer></v-spacer>
            <component
              style="white-space: pre-wrap"
              :is="$vuetify.breakpoint.smAndDown ? 'v-col' : 'div'"
              cols="12"
              class="text-muted bodyFont"
            >
              <div
                :class="
                  $vuetify.breakpoint.smAndDown
                    ? 'text-center mt-2'
                    : 'text-left ml-2 pl-1'
                "
                class="text-hover-primary"
              >
                <small>
                  Latest release: {{ getLatestVersion }} -
                  <span
                    ><vue-hoverable-date
                      :date="getLatestReleaseTimestamp"
                    ></vue-hoverable-date
                  ></span>
                </small>
              </div>
            </component>
          </v-card-title>
        </v-card>
      </v-col>
    </v-row>
  </v-footer>
</template>
<script>
export default {
  name: "KTFooter",
  computed: {
    showMenu() {
      return ![
        "welcome",
        "get-started",
        "started-team",
        "started-app",
        "started-releases",
        "started-invite",
      ].includes(this.$route.name);
    },
    getLatestVersion() {
      return "v" + process.env.VUE_APP_RELEASE.split("@")[1];
    },
    getLatestReleaseTimestamp() {
      return process.env.VUE_APP_RELEASE_DATE;
    },
  },
};
</script>
<style scoped>
.darkClass {
  /*background-color: #343434;*/
  background-color: #343434;
}
</style>
