var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('v-footer', {
    staticClass: "subtitle-1",
    class: _vm.$vuetify.theme.dark ? 'darkClass' : 'grey lighten-3',
    attrs: {
      "bottom": ""
    }
  }, [_c('v-row', {
    attrs: {
      "justify": "center"
    }
  }, [_c('v-col', {
    staticClass: "pb-0 px-0",
    attrs: {
      "cols": "12"
    }
  }, [_c('v-card', {
    staticClass: "text-center py-4",
    attrs: {
      "height": "80",
      "width": "100%",
      "flat": ""
    }
  }, [_c('v-card-title', {
    staticClass: "py-0",
    class: _vm.$vuetify.breakpoint.smAndDown ? 'text-center' : ''
  }, [_vm.$vuetify.breakpoint.smAndDown ? _c('v-col', {
    attrs: {
      "cols": "12"
    }
  }, [_c('img', {
    attrs: {
      "src": "/media/logos/footer_logo.svg"
    }
  })]) : _vm._e(), _c(_vm.$vuetify.breakpoint.smAndDown ? 'v-col' : 'div', {
    tag: "component",
    staticClass: "bodyFont",
    attrs: {
      "cols": "12"
    }
  }, [_c('div', [_c('a', {
    staticClass: "text-muted text-hover-primary",
    attrs: {
      "href": "https://testapp.io/",
      "target": "_blank"
    }
  }, [_vm._v(" Home ")]), _c('a', {
    staticClass: "text-muted mx-2 text-hover-primary",
    attrs: {
      "href": "https://help.testapp.io/",
      "target": "_blank"
    }
  }, [_vm._v(" Help ")]), _c('a', {
    staticClass: "text-muted text-hover-primary mx-2",
    attrs: {
      "href": "https://blog.testapp.io/",
      "target": "_blank"
    }
  }, [_vm._v(" Blog ")]), _c('a', {
    staticClass: "text-muted text-hover-primary mx-2",
    attrs: {
      "href": "https://help.testapp.io/topic/integrations/",
      "target": "_blank"
    }
  }, [_vm._v(" Integrations ")])])]), _c('v-spacer'), _vm.$vuetify.breakpoint.mdAndUp ? _c('div', [_c('img', {
    attrs: {
      "src": "/media/logos/footer_logo.svg"
    }
  })]) : _vm._e(), _c('v-spacer'), _c(_vm.$vuetify.breakpoint.smAndDown ? 'v-col' : 'div', {
    tag: "component",
    staticClass: "text-muted bodyFont",
    staticStyle: {
      "white-space": "pre-wrap"
    },
    attrs: {
      "cols": "12"
    }
  }, [_c('div', {
    staticClass: "text-hover-primary",
    class: _vm.$vuetify.breakpoint.smAndDown ? 'text-center mt-2' : 'text-left ml-2 pl-1'
  }, [_c('small', [_vm._v(" Latest release: " + _vm._s(_vm.getLatestVersion) + " - "), _c('span', [_c('vue-hoverable-date', {
    attrs: {
      "date": _vm.getLatestReleaseTimestamp
    }
  })], 1)])])])], 1)], 1)], 1)], 1)], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }